import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { getMapTypeImage, displayLabel } from "../../../../lib/helpers";
import {
  missionMapTypes,
  jpgFileInfo,
  sensorsType,
} from "../../../../lib/constants";
import AppButton from "../../../global/elements/AppButton";
import { fontWeightFive } from "../../../../lib/styleConstants";

const styles = {
  mapIcons: {
    height: "125px",
    width: "125px",
  },
  btnCls: {
    marginTop: "20px",
    width: "100%",
  },
};

const MapTypeInfo = ({ label, onClick, jpgFlag, type }) => {
  const mapTypeImage = getMapTypeImage(label);
  let selectedMapType = {};
  if (jpgFlag) {
    selectedMapType = jpgFileInfo;
  } else if (type === "sensors") {
    selectedMapType = sensorsType.find((obj) => obj.label == label);
  } else {
    selectedMapType = missionMapTypes.find((obj) => obj.label == label);
  }

  return (
    <Grid container columnSpacing={2} rowSpacing={1}>
      <Grid item xs={12}>
        <Box component="img" src={mapTypeImage} sx={styles.mapIcons} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={fontWeightFive}>
          {displayLabel(selectedMapType?.label)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{selectedMapType?.description}</Typography>
      </Grid>
      {type === "sensors" ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body2" sx={fontWeightFive}>
              Supported deliverables:&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body3">
              {selectedMapType?.allowedMapTypes.join(" , ")}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="body2" sx={fontWeightFive}>
              Delivery file formats:&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body3">
              {selectedMapType?.fileFormat}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {!jpgFlag && (
          <AppButton
            look="black"
            label={type === "sensors" ? "Select sensor" : "Select product"}
            onClick={onClick}
            addtionalStyle={styles.btnCls}
          />
        )}
      </Grid>
    </Grid>
  );
};

MapTypeInfo.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  jpgFlag: PropTypes.bool,
  type: PropTypes.string,
};

MapTypeInfo.defaultProps = {
  label: "",
  onClick: undefined,
  jpgFlag: false,
  type: "product",
};

export default MapTypeInfo;
